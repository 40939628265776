<template>
  <section>
    <v-container fluid>
      <v-row>
        <template v-if="allLoadingDash">
          <v-col md="3" v-for="n in 10" :key="n">
            <v-skeleton-loader max-width="420" type="image" height="120"></v-skeleton-loader>
          </v-col>
        </template>
        <template v-if="!allLoadingDash">
          <v-col md="3" v-for="(data, index) in allDashboard" :key="index">
            <div class="numberbox">
              <ul class="titletag">
                <li>{{ data.title }}</li>
                <li class="tag">
                  <router-link to="/dashboard">View Table</router-link>
                </li>
              </ul>
              <h1>{{ data.count }}</h1>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  computed: {
    ...mapGetters(["allDashboard", "allLoadingDash"]),
  },
  methods: {
    ...mapActions(["fetchDashboard"]),
  },
  created() {
    this.fetchDashboard();
    
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.numberbox {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  background: $white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  border-radius: 5px;
  height: 120px;
  padding: 30px 20px;
}
.titletag {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding-left: 0;
  align-items: center;
  width: 100%;
  font-weight: 500;
  li{
    &:first-of-type{
      margin-left: 0;
    }
  }
  .tag {
    font-size: $smallfont;
    background: $tagcolor;
    display: block;
    font-size: $smallfont;
    padding: 3px 8px;
    color: $white;
    border-radius: 5px;
    a {
      color: $white;
      text-decoration: none;
    }
  }
}
.footertag {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding-left: 0;
  align-items: center;
  width: 100%;
  font-size: $midfont;
  .tag {
    background: $tagcolorsecond;
    display: block;
    font-size: $midfont;
    padding: 3px 8px;
    color: $white;
    border-radius: 5px;
    color: $greentva;
    font-weight: 600;
    margin-right: 10px;
  }
}
h1 {
  font-size: 30px;
}
</style>
